<template>
  <div>
    <!-- Sidebar for mobile -->
    <TransitionRoot
      as="template"
      :show="sidebarOpen"
    >
      <Dialog
        as="div"
        class="relative z-50 lg:hidden"
        @close="sidebarOpen = false"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-900/80" />
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div
                  class="absolute left-full top-0 flex w-16 justify-center pt-5"
                >
                  <button
                    type="button"
                    class="-m-2.5 p-2.5"
                    @click="sidebarOpen = false"
                  >
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon
                      class="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </TransitionChild>
              <!-- Sidebar component, swap this element with another sidebar if you like -->
              <div
                class="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2"
              >
                <div
                  class="flex h-16 shrink-0 items-center text-viola-400 font-bold text-xl"
                >
                  <img
                    class="h-8 w-auto mr-3"
                    src="/icon.svg"
                    alt="Healistic"
                  >
                  {{ title }}
                </div>
                <nav
                  class="flex flex-1 flex-col"
                  aria-label="navigation"
                >
                  <ul
                    role="list"
                    class="flex flex-1 flex-col gap-y-7"
                  >
                    <li>
                      <ul
                        role="list"
                        class="-mx-2 space-y-1"
                      >
                        <li
                          v-for="item in NavigationConfig.main"
                          :key="item.name"
                        >
                          <NuxtLink
                            :href="item.href"
                            :class="[
                              (item.href.length > 1
                                && useRoute().path.includes(item.href))
                                || item.href == useRoute().path
                                ? 'bg-gray-50 text-indigo-600'
                                : '',
                              'text-gray-700 hover:text-indigo-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                            ]"
                            @click="sidebarOpen = false"
                          >
                            <component
                              :is="item.icon"
                              class="h-6 w-6 shrink-0"
                              aria-hidden="true"
                            />
                            {{ item.name }}
                          </NuxtLink>
                        </li>
                        <li>
                          <div
                            class="text-xs font-semibold leading-6 text-gray-400"
                          >
                            Tools
                          </div>
                          <ul
                            role="list"
                            class="-mx-2 mt-2 space-y-1"
                          >
                            <li
                              v-for="tool in NavigationConfig.tools"
                              :key="tool.name"
                            >
                              <NuxtLink
                                :href="tool.href"
                                class="text-gray-700 hover:text-indigo-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                active-class="bg-gray-50 text-indigo-600"
                              >
                                <span
                                  class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white"
                                >{{ tool.initial }}</span>
                                <span class="truncate">{{ tool.name }}</span>
                              </NuxtLink>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <NuxtLink
                            :class="[
                              'text-gray-700 hover:text-indigo-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                            ]"
                            @click="signOut"
                          >
                            <ArrowLeftStartOnRectangleIcon
                              class="h-6 w-6 shrink-0"
                              aria-hidden="true"
                            />
                            Logout
                          </NuxtLink>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div
      class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col"
    >
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div
        class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6"
      >
        <div
          class="flex h-16 shrink-0 items-center text-viola-400 font-bold text-xl"
        >
          <img
            class="h-8 w-auto mr-3"
            src="/icon.svg"
            alt="Healistic"
          >
          {{ title }}
        </div>
        <nav
          class="flex flex-1 flex-col"
          aria-label="navigation"
        >
          <ul
            role="list"
            class="flex flex-1 flex-col gap-y-7"
          >
            <li>
              <ul
                role="list"
                class="-mx-2 space-y-1"
              >
                <li
                  v-for="item in NavigationConfig.main"
                  :key="item.name"
                >
                  <NuxtLink
                    :href="item.href"
                    :class="[
                      (item.href.length > 1
                        && useRoute().path.includes(item.href))
                        || item.href == useRoute().path
                        ? 'bg-gray-50 text-indigo-600 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                        : '',
                      'text-gray-700 hover:text-indigo-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                    ]"
                  >
                    <component
                      :is="item.icon"
                      class="h-6 w-6 shrink-0"
                      aria-hidden="true"
                    />
                    {{ item.name }}
                  </NuxtLink>
                </li>
              </ul>
            </li>
            <li>
              <div class="text-xs font-semibold leading-6 text-gray-400">
                Tools
              </div>
              <ul
                role="list"
                class="-mx-2 mt-2 space-y-1"
              >
                <li
                  v-for="tool in NavigationConfig.tools"
                  :key="tool.name"
                >
                  <NuxtLink
                    :href="tool.href"
                    class="text-gray-700 hover:text-indigo-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                    active-class="bg-gray-50 text-indigo-600"
                  >
                    <span
                      class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white"
                    >{{ tool.initial }}</span>
                    <span class="truncate">{{ tool.name }}</span>
                  </NuxtLink>
                </li>
              </ul>
            </li>
            <li class="-mx-6 mt-auto">
              <NuxtLink
                class="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50 hover:cursor-pointer"
                @click="signOut"
              >
                <!-- <img v-if="user?.email" :src="user?.image" class="block h-8 w-8 rounded-full bg-gray-50 text-gray-500" alt="">
                                <UserCircleIcon v-else class="h-8 w-8 rounded-full bg-gray-50 text-gray-500" aria-hidden="true" /> -->
                <!--                <span class="sr-only">Your profile</span> -->
                <span aria-hidden="true">Logout</span>
              </NuxtLink>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <div
      class="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden"
    >
      <button
        type="button"
        class="-m-2.5 p-2.5 text-gray-700 lg:hidden"
        @click="sidebarOpen = true"
      >
        <span class="sr-only">Open sidebar</span>
        <Bars3Icon
          class="h-6 w-6"
          aria-hidden="true"
        />
      </button>
      <BaseTitle class="flex-1">
        {{ pageTitle }}
      </BaseTitle>
    </div>

    <main class="lg:py-10 lg:pl-72">
      <div class="px-4 sm:px-6 lg:px-8">
        <slot />
        <ModalTermsConditions />
      </div>
    </main>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import {
  Bars3Icon,
  XMarkIcon,
  ArrowLeftStartOnRectangleIcon,
} from '@heroicons/vue/24/outline';
import { useToast } from 'vue-toastification';
import { NavigationConfig } from '~/configs';

const supabase = useSupabaseClient();
const router = useRouter();
const toast = useToast();

const pageTitle = computed(() => {
  const path = useRoute().path;
  const page = NavigationConfig.main.find(
    item =>
      (item.href.length > 1 && path.includes(item.href)) || item.href === path,
  );
  return page ? page.name : '';
});

const signOut = async () => {
  const { error } = await supabase.auth.signOut();
  if (error) toast.error(error.message);
  else await router.push('/');
};

const title = 'Prescriber';
const sidebarOpen = ref(false);
</script>
